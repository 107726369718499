import { apiV2Config } from './Property'
import { AxiosPromise } from 'axios'
import axiosOrcatec from './axiosInstance'
import {
  IProjectSettings,
  IProjectRelation,
} from 'pages/Settings/Project/ProjectSettingsPage'
import {
  ProjectItem,
  IProjectItemsGroup,
  ProjectSection,
  ItemProgressEntry,
  ProjectDiscount,
  ProjectRebate,
  ProjectTax,
  ProjectFee,
  IProjectTab,
  IClients,
  EntityType,
  ProjectSettings,
  ProjectStatus,
} from 'features/Project/types'
import { IFWO } from 'containers/MainContent/Orcatec/Proposals/components/Form/FieldWorkOrder/FieldWorkOrder'
// import { ProjectItem, ProjectSection } from 'features/Project/types'
import { Companies } from 'types/Company'
import { Media } from 'types/Media'

interface Params {
  [key: string]: any
}

const getProject = (projectId: number, params?: { tab_id: number }) =>
  axiosOrcatec.get(`proposals/${projectId}`, { params }).then(res => res.data)

const deleteProject = (projectId: number) =>
  axiosOrcatec.delete(`proposals/${projectId}`).then(response => response.data)

const duplicateProject = (projectId: number, projectCode: string) =>
  axiosOrcatec
    .get(`/proposals/copy/${projectId}?code=${projectCode}`)
    .then(response => response.data)

export const updatePublicProject = (
  projectId: number,
  field: {
    files_from_customer?: Media[]
    infro_from_customer?: string
  },
) => {
  axiosOrcatec
    .patch(`public-customer-view/${projectId}/update`, field)
    .then(res => res.data)
}

export const updateProjectSettings = (
  params: Params,
): AxiosPromise<{ settings: IProjectSettings }> => {
  return axiosOrcatec
    .patch(`/setting/proposal`, params, apiV2Config)
    .then(response => response)
}

export const getProjectSettings = (): AxiosPromise<{
  settings: IProjectSettings
}> => {
  return axiosOrcatec
    .get(`/setting/proposal`, apiV2Config)
    .then(response => response)
}

export const updateProjectRelation = (
  proposalId: number,
  params: { [key in keyof IProjectRelation | string]: number },
): AxiosPromise<{ settings: IProjectRelation }> => {
  return axiosOrcatec
    .patch(`/proposals/${proposalId}/relations`, params)
    .then(res => res && res.data)
}

export const getProjectLogs = (
  proposalId: number,
): AxiosPromise<{ status_logs: ILogs[] }> => {
  return axiosOrcatec
    .get(`/proposals/${proposalId}/status-logs`)
    .then(response => response)
}
export const patchProjectSettings = (
  proposalId: number,
  data: Partial<ProjectSettings>,
): AxiosPromise<{ settings: IProjectRelation }> => {
  return axiosOrcatec
    .patch(`/proposals/${proposalId}/settings`, data)
    .then(res => res && res.data)
}

export const getProjectInfo = (proposalId: number) => {
  return axiosOrcatec
    .get(`/proposals/${proposalId}/info`)
    .then(res => res && res.data)
}

export const projectMarkAsSent = (proposal_id: number[]) => {
  return axiosOrcatec
    .post(`/proposals/mark-as-sent`, { proposal_id })
    .then(res => res && res.data)
}

const getItemById = (id: number) =>
  axiosOrcatec.get(`proposal/items/${id}`).then(res => res.data)

const addItem = (item: ProjectItem) =>
  axiosOrcatec.post('proposal/items', item).then(res => res.data)

const updateItem = (item: ProjectItem) =>
  axiosOrcatec.put(`proposal/items/${item.id}`, item).then(res => res.data)

const updatePublicItem = (itemId: number, field: keyof ProjectItem) =>
  axiosOrcatec
    .patch(`proposal/items/edit/${itemId}`, field)
    .then(res => res.data)

const deleteItem = (itemId: number) =>
  axiosOrcatec.delete(`proposal/items/${itemId}`).then(res => res.data)

const searchItems = (params: { search?: string; category_id?: number }) =>
  axiosOrcatec.get(`proposal/items`, { params }).then(res => res.data)

const reorderItems = (items: ProjectItem[]) =>
  axiosOrcatec.put(`proposal/items/reorder`, { items }).then(res => res.data)

////////////////////
const getDiscountById = (id: number) =>
  axiosOrcatec.get(`proposal/discounts/${id}`).then(res => res.data)

const addDiscount = (item: ProjectDiscount) =>
  axiosOrcatec.post('proposal/discounts', item).then(res => res.data)

const updateDiscount = (item: ProjectDiscount) =>
  axiosOrcatec.put(`proposal/discounts/${item.id}`, item).then(res => res.data)

const updatePublicDiscount = (itemId: number, field: keyof ProjectDiscount) =>
  axiosOrcatec
    .patch(`proposal/discounts/edit/${itemId}`, field)
    .then(res => res.data)

const deleteDiscount = (itemId: number) =>
  axiosOrcatec.delete(`proposal/discounts/${itemId}`).then(res => res.data)

const searchDiscounts = (params: { search?: string }) =>
  axiosOrcatec
    .get(`proposal/discounts/search`, { params })
    .then(res => res.data)

const reorderDiscounts = (discounts: ProjectDiscount[]) =>
  axiosOrcatec
    .put(`proposal/discounts/reorder`, { discounts })
    .then(res => res.data)

////////////////////
const getRebateById = (id: number) =>
  axiosOrcatec.get(`proposal/rebates/${id}`).then(res => res.data)

const addRebate = (item: ProjectRebate) =>
  axiosOrcatec.post('proposal/rebates', item).then(res => res.data)

const updateRebate = (item: ProjectRebate) =>
  axiosOrcatec.put(`proposal/rebates/${item.id}`, item).then(res => res.data)

const deleteRebate = (itemId: number) =>
  axiosOrcatec.delete(`proposal/rebates/${itemId}`).then(res => res.data)

const searchRebates = (params: { search?: string }) =>
  axiosOrcatec.get(`proposal/rebates/search`, { params }).then(res => res.data)

const reorderRebates = (rebates: ProjectRebate[]) =>
  axiosOrcatec
    .put(`proposal/rebates/reorder`, { rebates })
    .then(res => res.data)

///////////////////////
const addSection = (section: ProjectSection) =>
  axiosOrcatec
    .post<ProjectSection>(`proposal/sections`, section)
    .then(res => res.data)

const updateSectionTitle = (
  sectionId: number,
  field: Record<keyof ProjectSection, unknown>,
) =>
  axiosOrcatec
    .put(`proposal/sections/${sectionId}`, field)
    .then(res => res.data)

const duplicateSection = (sectionId: number) =>
  axiosOrcatec
    .put<ProjectSection>(`proposal/sections/copy/${sectionId}`)
    .then(res => res.data)

const reorderSections = (sections: ProjectSection[]) =>
  axiosOrcatec
    .put<ProjectSection>(`proposal/sections/reorder`, { sections })
    .then(res => res.data)

const deleteSection = (sectionId: number) =>
  axiosOrcatec.delete(`proposal/sections/${sectionId}`).then(res => res.data)

////////////////////
const addGroup = (group: IProjectItemsGroup) =>
  axiosOrcatec.post(`proposal/groups`, group).then(res => res.data)

const updateGroup = (group: IProjectItemsGroup) =>
  axiosOrcatec.put(`proposal/groups/${group.id}`, group).then(res => res.data)

const deleteGroup = (groupId: number) =>
  axiosOrcatec.delete(`proposal/groups/${groupId}`).then(res => res.data)

const reorderGroups = (groups: IProjectItemsGroup[]) =>
  axiosOrcatec.put(`proposal/groups/reorder`, { groups }).then(res => res.data)

///////////////////
const getTab = (tabId: number) =>
  axiosOrcatec.get(`proposal/tabs/${tabId}`).then(res => res.data)

const createTab = (data: { title: string; proposal_id: number }) =>
  axiosOrcatec.post<IProjectTab>(`proposal/tabs/`, data).then(res => res.data)

const updateTabField = (tabId: number, data: Record<string, unknown>) =>
  axiosOrcatec.patch(`proposal/tabs/${tabId}`, data).then(res => res.data)

const copyTab = (tabId: number) =>
  axiosOrcatec.put(`proposal/tabs/copy/${tabId}`).then(res => res.data)

const reorderTabs = (tabs: IProjectTab[]) =>
  axiosOrcatec.put(`proposal/tabs/reorder`, { tabs }).then(res => res.data)

const deleteTab = (tabId: number) =>
  axiosOrcatec.delete(`proposal/tabs/${tabId}`).then(res => res.data)

////////////////////

const createTax = (tax: ProjectTax) =>
  axiosOrcatec.post(`proposal/taxes`, tax).then(res => res.data)

const updateTax = (tax: ProjectTax) =>
  axiosOrcatec.put(`proposal/taxes/${tax.id}`, tax).then(res => res.data)

const deleteTax = (taxId: number) =>
  axiosOrcatec.delete(`proposal/taxes/${taxId}`).then(res => res.data)

////////////////////

const createFee = (fee: ProjectFee) =>
  axiosOrcatec.post(`proposal/fees`, fee).then(res => res.data)

const updateFee = (fee: ProjectFee) =>
  axiosOrcatec.put(`proposal/fees/${fee.id}`, fee).then(res => res.data)

const deleteFee = (feeId: number) =>
  axiosOrcatec.delete(`proposal/fees/${feeId}`).then(res => res.data)

////////////////////

const getItemEntry = (id: number) =>
  axiosOrcatec
    .get<ItemProgressEntry>(`proposal/entries/${id}`)
    .then(res => res.data)

const getItemEntriesList = (params: { item_id: number }) =>
  axiosOrcatec
    .get<ItemProgressEntry[]>(`proposal/entries`, { params })
    .then(res => res.data)

interface CreateEntrieBody extends ItemProgressEntry {
  item_id: number
}
const createItemEntry = (entry: CreateEntrieBody) =>
  axiosOrcatec
    .post<ItemProgressEntry>(`proposal/entries`, entry)
    .then(res => res.data)

const updateItemEntry = (entry: ItemProgressEntry) =>
  axiosOrcatec
    .put<ItemProgressEntry>(`proposal/entries/${entry.id}`, entry)
    .then(res => res.data)

const deleteItemEntry = (entryId: number) =>
  axiosOrcatec.delete(`proposal/entries/${entryId}`).then(res => res.data)

const addEntryNotes = (entryId: number, notes: string) =>
  axiosOrcatec
    .post(`proposal/entries/${entryId}/notes`, { text: notes })
    .then(res => res.data)

//contacts

const getProjectContacts = (projectId: number) =>
  axiosOrcatec
    .get<IClients>(`/proposals/${projectId}/contacts-extended`)
    .then(res => res.data?.data)

const attachContactToProject = (
  projectId: number,
  data: { entity_id: number; entity_type: EntityType },
) =>
  axiosOrcatec
    .post(`/proposals/${projectId}/parent-contacts`, data)
    .then(res => res.data)
const getParentContactById = (
  projectId: number,
  data: { entity_id: number; entity_type: EntityType },
) =>
  axiosOrcatec
    .get(
      `/proposals/${projectId}/${data.entity_type}/${data.entity_id}/parent `,
    )
    .then(res => res.data)

const attachChildContactToProject = (
  projectId: number,
  data: {
    proposal_parent_relation_id: number
    proposal_parent_id: number
    proposal_parent_type: EntityType
    parent_entity_id: number
    parent_entity_type: EntityType
    entity_id: number
    entity_type: EntityType
  },
) =>
  axiosOrcatec
    .post(`/proposals/${projectId}/child-contacts`, data)
    .then(res => res.data)

const detachContactFromProject = (
  projectId: number,
  data: {
    entity_id: number
    entity_type: EntityType
    proposal_relation_id: number
  },
) =>
  axiosOrcatec
    .delete(
      `/proposals/${projectId}/${data.entity_type}/proposal-relations/${data.proposal_relation_id}`,
    )
    .then(res => res.data)

const updateContactProjectRelation = (
  projectId: number,
  data: {
    proposal_relation_id: number
    proposal_relation_type: EntityType
    proposal_relation: string
  },
) =>
  axiosOrcatec
    .patch(`/proposals/${projectId}/relation`, data)
    .then(res => res.data)

const getRelationContactToProject = (projectId: number) =>
  axiosOrcatec
    .get(`/proposals/${projectId}/related-contacts`)
    .then(res => res.data)

const projectClientsSetPosition = async req => {
  const { data } = await axiosOrcatec.post(
    `/proposals_clients/set_position`,
    req,
  )
  return data
}

const projectPropertiesSetPosition = async req => {
  const { data } = await axiosOrcatec.post(
    `/proposals_properties/set_position`,
    req,
  )
  return data
}

const selectParentContact = async (
  proposalId: number,
  data: { main_relation_id: number; entity_type: EntityType; display: boolean },
) => {
  return axiosOrcatec.put(`/proposals/${proposalId}/set-display`, data)
}

const createRelationProperty = data => {
  return axiosOrcatec
    .post(`/proposals/attach-entities`, data)
    .then(response => response.data)
}

export const addContactToOrganization = (orgId, clientId, relation) => {
  const data = { client_id: clientId, contact_role: relation }
  return axiosOrcatec
    .put(`organizations/attach_contact/${orgId}`, data)
    .then(response => response.data)
}

export const getEntityAsParentAfterDetach = (
  proposalId,
  entityType,
  params,
) => {
  return axiosOrcatec
    .get(`/proposals/${proposalId}/${entityType}/parents`, { params })
    .then(response => response.data)
}

//WORK ORDER

const projectCreateWorkOrder = async req => {
  const { data } = await axiosOrcatec.post(
    `/proposals_properties/set_position`,
    req,
  )
  return data
}

const projectGetWorkOrderList = async req => {
  const { data } = await axiosOrcatec.get(
    `/proposals_properties/set_position`,
    req,
  )
  return data
}

const projectGetWorkOrderById = async req => {
  const { data } = await axiosOrcatec.get(
    `/proposals_properties/set_position`,
    req,
  )
  return data
}

const getProjectStatusList = () =>
  axiosOrcatec
    .get<ProjectStatus[]>('/setting/proposal/custom-statuses')
    .then(res => res.data)

const createProjectStatus = (data: ProjectStatus) =>
  axiosOrcatec
    .post<ProjectStatus>('/setting/proposal/custom-statuses', data)
    .then(res => res.data)

const updateProjectStatus = (data: ProjectStatus) =>
  axiosOrcatec
    .put<ProjectStatus>(`/setting/proposal/custom-statuses/${data.id}`, data)
    .then(res => res.data)

const deleteProjectStatus = (statusId: number) =>
  axiosOrcatec
    .delete(`/setting/proposal/custom-statuses/${statusId}`)
    .then(res => res.data)

const changeProjectStatusAndDelete = (data: {
  deleted_status_id: number
  changed_status_id: number
}) =>
  axiosOrcatec
    .post(`/setting/proposal/custom-statuses/replace`, data)
    .then(res => res.data)

export const ProjectStatusAPI = {
  getProjectStatusList,
  createProjectStatus,
  updateProjectStatus,
  deleteProjectStatus,
  changeProjectStatusAndDelete,
}

export const ItemEntriesAPI = {
  getItemEntry,
  getItemEntriesList,
  createItemEntry,
  updateItemEntry,
  deleteItemEntry,
  addEntryNotes,
}

export const ProjectTaxAPI = {
  createTax,
  updateTax,
  deleteTax,
}

export const ProjectFeeAPI = {
  createFee,
  updateFee,
  deleteFee,
}

export const ProjectTabsAPI = {
  getTab,
  createTab,
  updateTabField,
  deleteTab,
  copyTab,
  reorderTabs,
}

export const ProjectSectionsAPI = {
  addSection,
  updateSectionTitle,
  duplicateSection,
  reorderSections,
  deleteSection,
}

export const ProjectGroupsAPI = {
  addGroup,
  updateGroup,
  deleteGroup,
  reorderGroups,
}

export const ProjectCustomerView = {
  updatePublicProject,
}

export const ProjectWorkOrderAPI = {
  projectCreateWorkOrder,
  projectGetWorkOrderList,
  projectGetWorkOrderById,
}

const ProjectItemsAPI = {
  searchItems,
  getItemById,
  addItem,
  updateItem,
  deleteItem,
  reorderItems,
  searchDiscounts,
  getDiscountById,
  addDiscount,
  updateDiscount,
  updatePublicDiscount,
  deleteDiscount,
  reorderDiscounts,
  searchRebates,
  getRebateById,
  addRebate,
  updateRebate,
  deleteRebate,
  reorderRebates,
  updatePublicItem,
}

export const ProjectContactsAPI = {
  getProjectContacts,
  attachContactToProject,
  getParentContactById,
  attachChildContactToProject,
  detachContactFromProject,
  updateContactProjectRelation,
  getRelationContactToProject,
  projectClientsSetPosition,
  projectPropertiesSetPosition,
  createRelationProperty,
  addContactToOrganization,
  selectParentContact,
  getEntityAsParentAfterDetach,
}

export default ProjectItemsAPI

export const getProjectPatternCode = () => {
  return axiosOrcatec
    .get(`/setting/proposal/code-pattern`)
    .then(res => res && res.data)
}

export const updateProjectPatternCode = (data: {
  proposal_code_pattern: string
}) => {
  return axiosOrcatec
    .patch(`/setting/proposal/code-pattern`, data)
    .then(res => res && res.data)
}

export const generateNextProjectCode = userId => {
  return axiosOrcatec
    .get(`/proposals/code/generate?user_id=${userId}`)
    .then(res => res && res.data)
}
export const updateProjectCode = (
  projectId: number,
  data: { code: string },
) => {
  return axiosOrcatec.patch(`/proposals/${projectId}/partial`, data)
}
export const getProjectFWOSettings = (
  proposalId: number,
  companyId: number,
): { work_order: IFWO } => {
  if (![Companies.SoCal].includes(companyId)) return
  return axiosOrcatec
    .get(`/proposals/${proposalId}/fwo`)
    .then(res => res && res.data)
}

export const putProjectFWOSettings = (
  proposalId: number,
  params: IFWO,
  companyId: number,
): { work_order: IFWO } => {
  if (![Companies.SoCal].includes(companyId)) return
  return axiosOrcatec
    .put(`/proposals/${proposalId}/fwo`, params)
    .then(res => res && res.data)
}

// Signature
const setSignature = (
  projectId: number,
  data: {
    signed_tab_id: number
    signatory_name: string
    signature: string
  },
) =>
  axiosOrcatec
    .post<ItemProgressEntry>(`/proposal/${projectId}/store-signature`, data)
    .then(res => res.data)

const resetSignature = (projectId: number) =>
  axiosOrcatec.delete(`/proposal/${projectId}/reset-signature`)

const updateProjectField = (projectId: number, data: Record<string, unknown>) =>
  axiosOrcatec
    .patch(`/proposals/${projectId}/partial`, data)
    .then(res => res.data)

const updateExtraAssignedUsers = (projectId: number, data: number[]) =>
  axiosOrcatec
    .post(`/proposals/${projectId}/assign-extra-users`, { users: data })
    .then(res => res.data)

const loadProjectTemplate = (projectId: number, templateId: number) =>
  axiosOrcatec.put(`/proposal/${projectId}/load-template`, {
    template_id: templateId,
  })

export const getQuickbooksInfo = (projectID: number) => {
  return axiosOrcatec
    .get(`proposal/${projectID}/get-info-quickbooks`)
    .then(res => res && res.data)
}

export const exportProjectToQB = (projectID: number) => {
  return axiosOrcatec
    .post(`proposal/${projectID}/export-to-quickbooks`)
    .then(res => res && res.data)
}

export const getProjectContactsByKey = (params: {
  proposal_id: number[]
  q: string[]
}) => {
  return axiosOrcatec
    .get(`/proposal-contacts`, { params })
    .then(res => res && res.data)
}

export const ProjectAPI = {
  getProject,
  deleteProject,
  duplicateProject,
  setSignature,
  resetSignature,
  getQuickbooksInfo,
  exportProjectToQB,
  updateProjectField,
  loadProjectTemplate,
  getProjectContactsByKey,
  updateExtraAssignedUsers,
}
