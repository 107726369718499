import React from 'react'
import './style.scss'

const TermOfUseAndPrivacyLink = () => (
  <div
    className='mb-3 text-center termOfUse'
    /* style={{
      position: 'absolute',
      bottom: '0px',
      left: '50%',
      transform: 'translateX(-50%)',
    }} */
  >
    <p>
      <a
        href='https://orcatec.com/terms_and_conditions.pdf'
        className='text-primary'
        target='_blank'
        rel='noopener noreferrer'
      >
        Terms & Conditions
      </a>
      {`. `}
      <a
        href='https://orcatec.com/privacy_policy.pdf'
        className='text-primary'
        target='_blank'
        rel='noopener noreferrer'
      >
        Privacy Policy
      </a>
    </p>
  </div>
)

export default TermOfUseAndPrivacyLink
