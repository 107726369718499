import React from 'react'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import MediaPopap from '../../MediaPopap/MediaPopap'
import './Tab.scss'
import MainButton from '../../../../../components/buttons/MainButton'
import { PlusCircleOutlined, PlusCircleFilled } from '@ant-design/icons'

const Tab = ({
  toggleTab, //open or close tab
  isAddButtonClicked,
  onAddNewItem,
  itemsQuantity,
  title,
  isOpen,
  children,
  className,
  setFieldValues,
  disabled = false,
  ...mediaProps
}) => {
  const itemsName = title
    .toLowerCase()
    .trim()
    .replace(/\s/g, '_')
  return (
    <div className={`collapse-tabs`}>
      <div className={className ? className : ''}>
        {itemsName === 'media' && (
          <MediaPopap
            showMediaPopap={mediaProps.showMediaPopap}
            toggleMediaPopap={mediaProps.toggleMediaPopap}
            setFieldValues={setFieldValues}
          />
        )}
        <MainButton
          disabled={disabled}
          name={itemsName}
          className={`tab`}
          onClick={e => {
            e.preventDefault()
            toggleTab(e, itemsName)
          }}
          title={
            <>
              <div className='tab-title' disabled={disabled}>
                <div>{title}</div>
                {<div className='items-quantity'>{itemsQuantity}</div>}
                {(((itemsName === 'clients' || (itemsName === 'media' && itemsQuantity <= 100)) &&
                  !isAddButtonClicked) ||
                  itemsName === 'media') && (
                  <PlusCircleFilled
                    onClick={e => {
                      e.stopPropagation()
                      onAddNewItem(itemsName)
                      if (itemsName === 'media') mediaProps.toggleMediaPopap()
                    }}
                    style={
                      disabled
                        ? { cursor: 'not-allowed', pointerEvents: 'none', fontSize: '1.1rem' }
                        : { fontSize: '1.1rem' }
                    }
                  />
                )}
              </div>
              {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </>
          }
        />
      </div>

      {children}
      {/* {isOpen &&
        (itemsQuantity || itemsName === 'media' ? children : 'no data')} */}
    </div>
  )
}

export default Tab
