import { useAppSelector } from 'store/Orcatec/hooks'
import { Wrapper } from './TabContent.styles'
import {
  selectProject,
  selectProjectActiveTab,
  selectProjectSections,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import { ProjectStatus } from '../ProjectStatus/ProjectStatus'
import ProjectSections from '../ProjectSections'
import EndOfSystem from 'containers/MainContent/Orcatec/Proposals/components/Form/Tabs/EndOfSystem'
import SignatureInfo from '../SignatureInfo'
import PaymentInfo from '../PaymentInfo'
import InformationForCustomer from '../InformationForCustomer'
import { selectIsPriceVisible } from 'features/Settings/UsersAndGroups/permissionSlice'

export const TabContent = () => {
  const project = useAppSelector(selectProject)
  const sections = useAppSelector(selectProjectSections)
  const currentTab = useAppSelector(selectProjectActiveTab)
  const isVisiblePrice = useAppSelector(
    selectIsPriceVisible([
      project.user_id,
      ...(project?.extraAssignedUsers || []),
    ]),
  )

  const { require_signature } = useAppSelector(selectProjectSettings)

  return (
    <Wrapper>
      <ProjectStatus project={project} currentTab={currentTab} />

      <ProjectSections />

      <EndOfSystem
        sections={sections}
        currentTab={currentTab}
        showPrice={isVisiblePrice}
        proposalStatus={project.status}
        contractDate={project.contract_date}
        clientView
      />

      <PaymentInfo />

      {require_signature && <SignatureInfo />}

      <InformationForCustomer />
    </Wrapper>
  )
}
