import {
  DatePicker as AntDatePicker,
  DatePickerProps as AtnDatePikerProps,
} from 'antd'
import withLabelAndError from '../helpers/Wrapper'
import { DATE_FORMAT } from 'constants/dateFormats'
// import dayjs, { Dayjs } from 'dayjs'
import moment, { Moment } from 'moment-timezone'

type DatePickerProps = Omit<AtnDatePikerProps, 'value'> & {
  value?: string | number | Moment | Date | null | undefined
}

const DatePicker = ({ value, ...props }: DatePickerProps) => (
  <AntDatePicker
    format={DATE_FORMAT}
    value={value ? moment(value) : null}
    style={{ width: '100%' }}
    getPopupContainer={triggerNode => triggerNode?.parentNode}
    {...props}
  />
)

export const DatePickerWithLabelAndError = withLabelAndError<DatePickerProps>(
  DatePicker,
)
