import * as types from '../../types'
import { getProposalTemplate } from '../proposal/proposalTable'

export const toggleTimeoff = () => (dispatch, getState) => {
  let state = getState()
  dispatch({
    type: types.modalTimeoff.TOGGLE_TIMEOFF_MODAL,
    time_start: state.orcatec.dispatchSlice.time_start,
    time_end: state.orcatec.dispatchSlice.time_end,
  })
}

export const toggleEditTimeoff = item => {
  return {
    type: types.modalTimeoff.TOGGLE_EDIT_TIMEOFF_MODAL,
    item,
  }
}

export const changeTimeoffField = (field, value) => {
  return {
    type: types.modalTimeoff.CHANGE_TIMEOFF_FIELD,
    field,
    value,
  }
}
