import React, { useState, useEffect } from 'react'

import { thousandSeparator } from '../../../../../helpers/thousandSeparator'
import { itemsFilter } from '../../../../../api/Proposal'

/////////////FIELDS///////////////

const equipmentSettingsArray = [
  { name: 'type', placeholder: 'Type', isObject: true },
  { name: 'brand', placeholder: 'Brand', isObject: true },
  { name: 'model', placeholder: 'Model', isObject: true },
  { name: 'size', placeholder: 'Size', isObject: false },
  { name: 'cat5', placeholder: 'Cat1', isObject: false },
  { name: 'cat6', placeholder: 'Cat2', isObject: false },
]

const discountSettingsArray = [
  { name: 'description', placeholder: 'Description', isObject: false },
  { name: 'expiration', placeholder: 'Expiration', isObject: false },
]

export const getFields = title => {
  switch (title) {
    case 'Equipment':
    case 'Materials':
    case 'Parts':
    case 'Supplies':
      return equipmentSettingsArray
    // return equipmentSettingsArray
    // return equipmentSettingsArray
    case 'Scope of Work':
      return [{ name: 'name', placeholder: 'Scope Of Work', isObject: false }]
    case 'Warranty':
      return [
        { name: 'description', placeholder: 'Description', isObject: false },
        { name: 'term', placeholder: 'Term', isObject: false },
      ]
    case 'Instants':
      return discountSettingsArray
    case 'Rebates':
      return discountSettingsArray
    default:
      return []
  }
}
/////////////FIELDS///////////////

//check if field is object and it has a key//
export const validObj = (field, source) => {
  return source && typeof source[field.name] === 'object' && source[field.name]
}
//check if field is object and it has a key//

export const configureInput = (object, description, title) => {
  const fields = getFields(title)
  let string = fields.reduce((acc, field, fieldIdx, self) => {
    const value = getValueFromItemByField(object, field) //validObj(field, object) ? object[field.name].name : object[field.name]
    acc = acc + ((fieldIdx === 1 || fieldIdx === 2) && !!value ? ` ${value}` : value ? value : '')
    return acc
  }, '')
  if (description) string = string + ' ' + description
  return string
}

//////////////////////my utils///////////

let handler

export const useDebounce = function(value = '', delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    if (debouncedValue) {
      handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
    } else {
      setDebouncedValue(value)
    }
    return () => {
      if (handler) {
        clearTimeout(handler)
        handler = null
      }
    }
  }, [value, delay])
  return debouncedValue
}

export const getValueFromItemByField = (item, field) => {
  return validObj(field, item)
    ? item[field.name].name
      ? item[field.name].name
      : ''
    : item[field.name]
    ? item[field.name]
    : ''
}

export const getFieldsToFilter = (currentFieldIdx, fields) => {
  return fields /* .slice(0, currentFieldIdx + 1) */
}

export const filterArrayToUniqueByField = (array, field) => {
  return array.filter(
    (item, itemIdx, self) =>
      self.findIndex(v => getValueFromItemByField(v, field) === getValueFromItemByField(item, field)) === itemIdx,
  )
}

export const filterArrayByMultipleFieldsInControlledObject = (
  array,
  fields,
  controlledObject,
  eventType,
  currentFieldIdx,
) => {
  return (
    array &&
    array.filter(item =>
      fields.every((field, idx, self) => {
        const isFirstField = idx === 0
        const isLastField = idx === self?.length - 1
        //const isLastFromAll = allFields ? allFields[allFields?.length - 1].name === field.name : false
        let sourceValue = getValueFromItemByField(item, field).replace(/\s/g, ' ')
        let targetValue = getValueFromItemByField(controlledObject, field).replace(/\s/g, ' ')
        if (sourceValue === '--') sourceValue = ''
        if (targetValue === '--') targetValue = ''
        return ((eventType === 'onChange' || currentFieldIdx < idx) && currentFieldIdx !== self?.length - 1) ||
          self?.length === 1
          ? sourceValue.toLowerCase().includes(targetValue.toLowerCase())
          : sourceValue === targetValue //!targetValue ? true : (idx === 0 //|| eventType === 'onChange'
        //? sourceValue.toLowerCase().includes(targetValue.toLowerCase())
        //:  sourceValue === targetValue)
      }),
    )
  )
}

export const filterArrayByCurrentField = (array, field, fieldsSettings, controlledObject, eventType) => {
  const currentFieldIdx = fieldsSettings.findIndex(item => item.name === field.name)
  const fields = currentFieldIdx < 0 ? fieldsSettings : getFieldsToFilter(currentFieldIdx, fieldsSettings)

  return filterArrayByMultipleFieldsInControlledObject(array, fields, controlledObject, eventType, currentFieldIdx)
}

export const clearNextFieldsOnSearch = (fieldIdx, objectToClear, fieldsSettings) => {
  const isLastFieldInSettings = fieldsSettings?.length - 1 === fieldIdx
  if (!isLastFieldInSettings) {
    for (let i = fieldIdx + 1; i < fieldsSettings?.length; i++) {
      fieldsSettings[i].isObject
        ? (objectToClear[fieldsSettings[i].name].name = '')
        : (objectToClear[fieldsSettings[i].name] = '')
    }
  }
}

export const setCurrentValueInNewItemData = (value, copyOfnewItemData, field) => {
  field.isObject ? (copyOfnewItemData[field.name].name = value) : (copyOfnewItemData[field.name] = value)
}

export const setValuesInObjectIfSingleOrNoOption = (
  copyOfTargetObject,
  options,
  fieldsSettings,
  newfilteredItemsList,
  itemsList,
  eventType,
) => {
  fieldsSettings.forEach(field => {
    if (options[field.name]?.length === 1) {
      if (field.isObject) {
        copyOfTargetObject[field.name].name =
          !!options[field.name][0][field.name] && !!options[field.name][0][field.name].name
            ? options[field.name][0][field.name].name
            : '--'
      } else {
        copyOfTargetObject[field.name] = options[field.name][0][field.name] ? options[field.name][0][field.name] : '--'
      }
      //newfilteredItemsList = filterArrayByCurrentField(itemsList, field, fieldsSettings, copyOfTargetObject, eventType)
    } else if (!options[field.name]?.length) {
      if (field.isObject) {
        copyOfTargetObject[field.name].name = '--'
      } else {
        copyOfTargetObject[field.name] = '--'
      }
    }
  })
}

export const IconPlaceholder = () => <div className='proposal-items-icon-placeholder' />

export const getFiles = item => {
  const files = Array.isArray(item.files) ? item.files : []
  const getAttachmentFieldName = idx => {
    return idx === 0 ? 'attachment_one' : idx === 1 ? 'attachment_two' : 'attachment_three'
  }
  const getFile = idx =>
    files[idx] ? files[idx] : item[getAttachmentFieldName(idx)] ? item[getAttachmentFieldName(idx)] : null

  return [0, 1, 2].reduce((acc, num) => {
    const file = getFile(num)
    if (file) acc.push(file)
    return acc
  }, [])
}
