import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd'
import withLabelAndError from '../helpers/Wrapper'

interface SelectProps extends AntSelectProps {
  groupBy?: string
  error?: string | string[]
  name: string
  onChange?: (e: Event, value: any) => void
}

const Select = ({ name, onChange, children, ...props }: SelectProps) => {
  // const options = groupBy
  //   ? groupArray(props.options || [], groupBy)
  //   : props.options

  return !!children ? (
    <AntSelect
      style={{ width: '100%' }}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      status={props.error ? 'error' : undefined}
      // options={props.options}
      onChange={value =>
        onChange?.(
          {
            target: {
              name,
              value,
            },
          },
          value,
        )
      }
      {...props}
    >
      {children}
    </AntSelect>
  ) : (
    <AntSelect
      style={{ width: '100%' }}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      status={props.error ? 'error' : undefined}
      options={props.options}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      onChange={value =>
        onChange?.(
          {
            target: {
              name,
              value,
            },
          },
          value,
        )
      }
      {...props}
    />
  )
}

export const SelectWithLabelAndError = withLabelAndError<SelectProps>(Select)

/* const groupArray = (originalArray: any[], groupBy: string) => {
  return originalArray.reduce((acc, asisign) => {
    const existingGroup = acc.find(group => group.label === asisign[groupBy])
    if (existingGroup) {
      existingGroup.options.push(asisign)
    } else {
      acc.push({ label: asisign[groupBy], options: [asisign] })
    }

    return acc
  }, [])
}
 */
