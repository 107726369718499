import {
  Button,
  Checkbox,
  Input,
  Popconfirm,
  Popover,
  Radio,
  Spin,
  Tooltip,
} from 'antd'
import NumberFormat from 'react-number-format'
import { DragIndicator } from '@material-ui/icons'
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LockOutlined,
  MoreOutlined,
  SearchOutlined,
  UnlockOutlined,
  WarningFilled,
} from '@ant-design/icons'
import { OptionIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { ProjectItem, ProjectStatusGroup } from 'features/Project/types'
import { priceToView } from 'helpers/thousandSeparator'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  SemiBold,
  Actions,
  Wrapper,
  CustomLabel,
  GrossMargin,
  Content,
  ApproveButtons,
  RowTitle,
} from './TableRow.styles'
import { DraggableProvided } from 'react-beautiful-dnd'
import { Error } from 'types/Error'
import { useEffect, useRef, useState } from 'react'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { Attachments } from 'features/Items/components/Item/components/Attachments/Attachments'
import { ItemAttachment } from 'features/Items/types'
import {
  ProjectPermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
import {
  selectProject,
  selectProjectSettings,
} from 'features/Project/projectSelectors'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import { ProjectItemProgress } from '../../../ProjectItemProgress/ProjectItemProgress'
import { useDispatch } from 'react-redux'
import { itemUpdated } from 'features/Project/slices/projectItemsSlice'
import { formatStringToNumber } from 'helpers/numbers'
import { ProgressInfo } from '../../../ProjectItemProgress/components/ProgressInfo'
import { useUnits } from 'features/Items/hooks/useUnits'

const { TextArea } = Input

interface Props {
  error: Error | null
  isOption?: boolean
  item: ProjectItem
  editingItem: ProjectItem | null
  loading: boolean
  onChangeItem: (e: {
    target: {
      name: string
      value: string | number | boolean | null
    }
  }) => void
  onSaveItem: (item: ProjectItem) => void
  onDeleteItem: (item: ProjectItem) => void
  onEditItem: (item: ProjectItem) => void
  onCancel: () => void
  onSearchItem: (item: ProjectItem) => void
  onAddOption?: () => void
  onCheckGrossMargin: () => void
  onApproveItem?: (item: ProjectItem) => void
  disabled?: boolean
  provided?: DraggableProvided
  showProgress?: boolean
}

export const TableRow = ({
  error,
  isOption,
  disabled,
  editingItem,
  loading,
  item,
  provided,
  onEditItem,
  onChangeItem,
  onSaveItem,
  onDeleteItem,
  onSearchItem,
  onAddOption,
  onCancel,
  onCheckGrossMargin,
  onApproveItem,
  showProgress,
}: Props) => {
  const ref = useRef(null)
  const isEditingItem = item.id === editingItem?.id
  const { units } = useUnits()

  const { user_id, extraAssignedUsers } = useAppSelector(selectProject)
  const isCostVisible = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.PROJECT,
      ProjectPermissions.PROJECT_CAN_READ_COST,
    ),
  )
  const [editMode, setEditMode] = useState(false)
  const [showProgressForm, setShowProgressForm] = useState(false)

  useEffect(() => {
    if (!editingItem?.id) setEditMode(true)
  }, [])

  const handleRowBlur = () => {
    if (!editMode || !editingItem) return

    if (isEditingItem && !loading) {
      onSaveItem(editingItem)
    }
  }

  useOnClickOutside(ref, handleRowBlur)

  const { multiple_choice_items, show_item_description_only } = useAppSelector(
    selectProjectSettings,
  )
  const { status: projectStatus } = useAppSelector(selectProject)

  const dispatch = useDispatch()

  const handleBlurNumberField = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (value) return

    onChangeItem({
      target: {
        name,
        value: 0,
      },
    })
  }

  const handleUploadFiles = (files: ItemAttachment[]) => {
    onChangeItem({
      target: {
        name: 'attachments',
        value: files,
      },
    })
  }

  const handleEditItem = (item: ProjectItem) => {
    setEditMode(true)
    onEditItem(item)
  }

  const handleItemUpdate = (updatedItem: ProjectItem) => {
    dispatch(itemUpdated(updatedItem))
  }

  const handleRequiredToPayChange = () => {
    onChangeItem({
      target: {
        name: 'required_to_pay',
        value: !editingItem?.required_to_pay,
      },
    })
  }

  const handleZeroFieldFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e?.target
    if (editingItem?.[name as keyof ProjectItem] === 0)
      onChangeItem({
        target: {
          name,
          value: '',
        },
      })
  }

  return (
    <Spin spinning={loading}>
      <Wrapper ref={ref}>
        <Content
          key={item.id}
          ref={provided?.innerRef}
          {...provided?.draggableProps}
          unchecked={!!item.id && !!isOption && !item.checked_option}
          notApproved={!!item.id && !item.approved}
        >
          {!isOption &&
            multiple_choice_items &&
            (isEditingItem
              ? !editingItem?.required_to_pay
              : !item.required_to_pay) && (
              <Checkbox
                style={{
                  position: 'absolute',
                  left: -20,
                  top: 10,
                }}
                checked={
                  isEditingItem
                    ? editingItem?.use_calculation
                    : item.use_calculation
                }
                onChange={() => {
                  isEditingItem
                    ? onChangeItem({
                        target: {
                          name: 'use_calculation',
                          value: !editingItem?.use_calculation,
                        },
                      })
                    : onSaveItem({
                        ...item,
                        use_calculation: !item.use_calculation,
                      })
                }}
                disabled={disabled}
              />
            )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: '#aaa',
              fontSize: 18,
              gap: 5,
            }}
          >
            {!!isOption && (
              <Radio
                style={{
                  justifySelf: 'flex-end',
                  alignSelf: 'center',
                  margin: 0,
                  cursor: 'pointer',
                }}
                disabled={!item?.id}
                checked={item.checked_option}
                onClick={() => {
                  if (disabled || editingItem) return
                  onSaveItem({
                    ...item,
                    checked_option: true,
                  })
                }}
              />
            )}

            {!disabled && !!item.id && (
              <span {...provided?.dragHandleProps}>
                <Tooltip title={disabled ? null : 'Move'} mouseLeaveDelay={0}>
                  <DragIndicator
                    style={{
                      cursor: disabled ? 'not-allowed' : 'grab',
                    }}
                  />
                </Tooltip>
              </span>
            )}
          </div>

          <div
            style={{
              display: 'grid',
              gridAutoColumns: 'auto 1fr',
              gap: 5,
            }}
          >
            {isEditingItem ? (
              <div>
                <RowTitle>Name * </RowTitle>
                <div style={{ display: 'flex' }}>
                  <TextArea
                    name='name'
                    size='small'
                    value={editingItem?.name}
                    onChange={onChangeItem}
                    placeholder='Name'
                    autoSize
                    style={{ borderColor: error?.name ? 'red' : '#d9d9d9' }}
                  />
                  <p
                    style={{
                      border: '1px solid #d9d9d9',
                      display: 'flex',
                      alignItems: 'center',
                      padding: 3,
                      borderLeft: 'none',
                    }}
                  >
                    <SearchOutlined
                      onClick={() => {
                        setEditMode(false)
                        onSearchItem?.(item)
                      }}
                      style={{ fontSize: 18 }}
                    />
                  </p>
                </div>
                {error?.name && (
                  <p style={{ fontSize: '0.8rem', color: 'red' }}>
                    {error?.name}
                  </p>
                )}
              </div>
            ) : (
              <SemiBold>
                {!item.global_item_id && (
                  <CustomLabel>
                    <Tooltip
                      title='Custom item (not from "Price pages")'
                      mouseLeaveDelay={0}
                    >
                      *
                    </Tooltip>
                  </CustomLabel>
                )}
                <span
                  style={{
                    cursor: disabled ? 'initial' : 'pointer',
                  }}
                  onClick={() => {
                    if (disabled || editingItem) return
                    handleEditItem(item)
                  }}
                >
                  {item.name}
                </span>
              </SemiBold>
            )}

            <AccessControl
              author={[user_id, ...(extraAssignedUsers || [])]}
              scopes={[ProjectPermissions.PROJECT_CAN_VIEW_ITEM_DESCRIPTON]}
            >
              {isEditingItem ? (
                <div>
                  <RowTitle>Description</RowTitle>
                  <TextArea
                    name='description'
                    size='small'
                    value={editingItem?.description}
                    onChange={e =>
                      onChangeItem({
                        target: {
                          name: 'description',
                          value: e.target.value.trimStart(),
                        },
                      })
                    }
                    placeholder='Description'
                    autoSize
                  />
                </div>
              ) : (
                <p
                  style={{ whiteSpace: 'pre-wrap', cursor: 'pointer' }}
                  onClick={() => {
                    if (disabled || editingItem) return
                    handleEditItem(item)
                  }}
                >
                  {item.description}
                  {show_item_description_only && !item.description && (
                    <Popover
                      content={
                        <p>
                          Project settings -&gt; &quot;Show Items Description
                          only&quot; setting is ON, but no Item description has
                          been provided.<br></br>This will result in a blank
                          Item row on the Customer view.
                        </p>
                      }
                      title={
                        <p>
                          &quot;Show Items Description only&quot; setting is ON
                          <WarningFilled
                            style={{ color: '#faad14', marginLeft: 5 }}
                          />
                        </p>
                      }
                    >
                      <span
                        style={{
                          fontStyle: 'italic',
                          color: '#ef9494',
                        }}
                      >
                        No item description
                      </span>
                      {'  '}
                      <WarningFilled
                        style={{ color: '#faad14', fontSize: 16 }}
                      />
                    </Popover>
                  )}
                </p>
              )}
            </AccessControl>

            {showProgress && !!item.id && (
              <ProgressInfo
                onClick={() => setShowProgressForm(true)}
                progress={{
                  percent: item.progress,
                  installed: item.installed,
                  estimated: item.qty,
                }}
                unit={item.unit || null}
                // disabled={projectStatus !== ProjectStatus.Contract}
              />
            )}

            <Attachments
              ref={ref}
              imageSize={35}
              data={isEditingItem ? editingItem?.attachments : item.attachments}
              disabled={disabled || !isEditingItem}
              onUpload={handleUploadFiles}
              tooltip
            />
          </div>
          <p style={{ textAlign: 'center' }}>
            {isEditingItem ? (
              <>
                <RowTitle>MATL</RowTitle>
                <Checkbox
                  name='is_material'
                  checked={editingItem?.is_material}
                  onChange={e =>
                    onChangeItem({
                      target: {
                        name: 'is_material',
                        value: e.target.checked,
                      },
                    })
                  }
                />
              </>
            ) : (
              item.is_material && <CheckOutlined />
            )}
          </p>
          <p style={{ textAlign: 'center' }}>
            {isEditingItem ? (
              <>
                <RowTitle>TAX</RowTitle>
                <Checkbox
                  name='taxable'
                  checked={editingItem?.taxable}
                  onChange={e =>
                    onChangeItem({
                      target: {
                        name: 'taxable',
                        value: e.target.checked,
                      },
                    })
                  }
                />
              </>
            ) : (
              item.taxable && <CheckOutlined />
            )}
          </p>
          <AccessControl
            author={[user_id, ...(extraAssignedUsers || [])]}
            additionalAccess={!!isCostVisible}
            scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
          >
            <p>
              {isEditingItem ? (
                <>
                  <RowTitle>Cost</RowTitle>
                  <NumberFormat
                    allowNegative={false}
                    thousandSeparator
                    name='net_price'
                    decimalScale={3}
                    customInput={Input}
                    value={editingItem?.net_price}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeItem({
                        target: {
                          name: 'net_price',
                          value: formatStringToNumber(e),
                        },
                      })
                    }}
                    onBlur={handleBlurNumberField}
                    prefix='$'
                    onFocus={handleZeroFieldFocus}
                  />
                </>
              ) : (
                `${priceToView(item.net_price, false, 3)}`
              )}
            </p>
          </AccessControl>
          <AccessControl
            author={[user_id, ...(extraAssignedUsers || [])]}
            additionalAccess={!!isCostVisible}
            scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
          >
            <p>
              {isEditingItem ? (
                <>
                  <RowTitle>Margin</RowTitle>
                  <GrossMargin>
                    <Checkbox
                      checked={editingItem?.gross_margin !== null}
                      onChange={onCheckGrossMargin}
                    />
                    {editingItem?.gross_margin === null ? (
                      <Input value='n/a' disabled /* size='small' */ />
                    ) : (
                      <NumberFormat
                        allowNegative={false}
                        thousandSeparator
                        decimalScale={2}
                        name='gross_margin'
                        customInput={Input}
                        value={editingItem?.gross_margin}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChangeItem({
                            target: {
                              name: 'gross_margin',
                              value: formatStringToNumber(e),
                            },
                          })
                        }}
                        onBlur={handleBlurNumberField}
                        suffix='%'
                        onFocus={handleZeroFieldFocus}
                        // size='small'
                      />
                    )}
                  </GrossMargin>
                </>
              ) : (
                <p>
                  {item.gross_margin === null ? 'n/a' : `${item.gross_margin}%`}
                </p>
              )}
            </p>
          </AccessControl>
          <AccessControl
            author={[user_id, ...(extraAssignedUsers || [])]}
            scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
          >
            <p>
              {isEditingItem ? (
                <>
                  <RowTitle>Price</RowTitle>
                  <NumberFormat
                    allowNegative={false}
                    value={editingItem?.retail_price}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeItem({
                        target: {
                          name: 'retail_price',
                          value: formatStringToNumber(e),
                        },
                      })
                    }}
                    onFocus={handleZeroFieldFocus}
                    disabled={editingItem?.gross_margin !== null}
                    onBlur={handleBlurNumberField}
                    name='retail_price'
                    customInput={Input}
                    prefix='$'
                    // size='small'
                    thousandSeparator
                    decimalScale={3}
                  />
                </>
              ) : (
                <>
                  {priceToView(
                    isEditingItem
                      ? editingItem?.retail_price
                      : item.retail_price,
                    false,
                    3,
                  )}
                </>
              )}
            </p>
          </AccessControl>

          <>
            <p>
              {isEditingItem ? (
                <>
                  <RowTitle>Qty</RowTitle>
                  <NumberFormat
                    allowNegative={false}
                    thousandSeparator
                    name='qty'
                    customInput={Input}
                    value={editingItem?.qty}
                    onValueChange={values =>
                      onChangeItem({
                        target: {
                          name: 'qty',
                          value: values.floatValue ?? '',
                        },
                      })
                    }
                    onBlur={handleBlurNumberField}
                    onFocus={handleZeroFieldFocus}
                    decimalScale={2}
                    // size='small'
                  />
                </>
              ) : (
                item.qty
              )}
            </p>

            <p>
              {isEditingItem && <RowTitle>Unit</RowTitle>}

              <p
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {item?.unit_id ? units[item?.unit_id]?.name : 'Qty'}
              </p>
            </p>
          </>

          <AccessControl
            author={[user_id, ...(extraAssignedUsers || [])]}
            scopes={[ProjectPermissions.PROJECT_CAN_READ_PRICE]}
          >
            <p>
              <SemiBold>
                {isEditingItem ? (
                  <>
                    <RowTitle>Total</RowTitle>
                    {priceToView(
                      (editingItem?.retail_price || 0) *
                        (editingItem?.qty || 0),
                    )}
                  </>
                ) : (
                  priceToView(item.retail_price * (item.qty || 0))
                )}
              </SemiBold>
            </p>
          </AccessControl>

          <Actions>
            {isEditingItem ? (
              <>
                <Tooltip title='Save item' mouseLeaveDelay={0}>
                  <CheckOutlined
                    style={{ color: 'green' }}
                    onClick={() => {
                      if (!editingItem || loading) return

                      onSaveItem(editingItem)
                    }}
                  />
                </Tooltip>
                <Tooltip title='Cancel' mouseLeaveDelay={0}>
                  <CloseOutlined style={{ color: 'red' }} onClick={onCancel} />
                </Tooltip>
              </>
            ) : (
              <Tooltip
                title={disabled ? null : 'Edit item'}
                mouseLeaveDelay={0}
              >
                <EditOutlined
                  style={{
                    cursor: disabled ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => {
                    if (disabled || editingItem) return
                    handleEditItem(item)
                  }}
                />
              </Tooltip>
            )}
            {!isEditingItem && !isOption && (
              <Tooltip
                title={
                  disabled || editingItem
                    ? null
                    : item.is_hidden
                    ? 'Hide for customer'
                    : 'Display for customer'
                }
                mouseLeaveDelay={0}
              >
                <EyeOutlined
                  style={{
                    color: item.is_hidden ? 'red' : '#4285f4',
                    cursor: disabled ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => {
                    if (disabled) return

                    onSaveItem({
                      ...item,
                      is_hidden: !item.is_hidden,
                    })
                  }}
                />
              </Tooltip>
            )}

            {!!item.id && isEditingItem && !item.checked_option && (
              <Tooltip title='Delete' mouseLeaveDelay={0}>
                <Popconfirm
                  placement='left'
                  getPopupContainer={trigger =>
                    trigger.parentElement || document.body
                  }
                  title='Delete item?'
                  onConfirm={() => onDeleteItem(item)}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            )}

            {!!item.id && isEditingItem && (
              <>
                {!isOption && (
                  <Tooltip title='Add an option' mouseLeaveDelay={0}>
                    <OptionIcon
                      onClick={() => {
                        if (!item.approved) return

                        onAddOption?.()
                      }}
                      style={{
                        cursor: !item.approved ? 'not-allowed' : 'pointer',
                      }}
                    />
                  </Tooltip>
                )}

                {multiple_choice_items && !isOption && (
                  <Tooltip
                    title={`${
                      editingItem?.required_to_pay ? 'Unlock' : 'Lock'
                    } an item`}
                    mouseLeaveDelay={0}
                  >
                    {editingItem?.required_to_pay ? (
                      <LockOutlined onClick={handleRequiredToPayChange} />
                    ) : (
                      <UnlockOutlined onClick={handleRequiredToPayChange} />
                    )}
                  </Tooltip>
                )}
              </>
            )}

            {isEditingItem && !!onSearchItem && (
              <Tooltip title='More' mouseLeaveDelay={0}>
                <MoreOutlined
                  rotate={90}
                  onClick={() => {
                    setEditMode(false)
                    onSearchItem(item)
                  }}
                />
              </Tooltip>
            )}
          </Actions>

          {!!item.id && !item.approved && (
            <AccessControl
              additionalAccess={projectStatus === ProjectStatusGroup.Contract}
              author={[user_id, ...(extraAssignedUsers || [])]}
              scopes={[ProjectPermissions.PROJECT_CAN_EDIT_CONTRACT]}
            >
              <ApproveButtons>
                <Popconfirm
                  placement='left'
                  getPopupContainer={trigger =>
                    trigger.parentElement || document.body
                  }
                  title='Delete item?'
                  onConfirm={() => onDeleteItem(item)}
                >
                  <Button danger size='small' loading={loading}>
                    Remove
                  </Button>
                </Popconfirm>
                <Button
                  type='primary'
                  size='small'
                  onClick={() =>
                    onApproveItem?.({
                      ...item,
                      approved: true,
                    })
                  }
                  loading={loading}
                >
                  Approve
                </Button>
              </ApproveButtons>
            </AccessControl>
          )}
        </Content>

        {showProgressForm && (
          <ProjectItemProgress
            item={item}
            onClose={(updatedItem: ProjectItem) => {
              setShowProgressForm(false)

              handleItemUpdate(updatedItem)
            }}
            disabled={projectStatus !== ProjectStatusGroup.Contract}
          />
        )}
      </Wrapper>
    </Spin>
  )
}
