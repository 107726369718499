import React from 'react'
import { Modal } from 'react-bootstrap'
import MainButton from '../../components/buttons/MainButton'

const ReconfirmModal = ({ modal, resend, cancel, isLoading }) => {
  return (
    <Modal show={modal} onHide={cancel} backdrop='static'>
      {/* <Modal.Header closeButton>        
      </Modal.Header> */}
      <Modal.Body>
        <div className='w-100 d-flex flex-column align-items-center mb-5'>
          <div className='text-center' style={{ fontWeight: 500, fontSize: '1.5rem' }}>
            This email is not verified.
          </div>
          <div className='text-center' style={{ fontWeight: 500, fontSize: '1.2rem' }}>
            Do you want to resend the verification email?
          </div>
        </div>
        <div className='d-flex justify-content-around mt-5'>
          <MainButton title='Cancel' type='danger' onClick={cancel} />
          <MainButton title='Resend' onClick={resend} isFetching={isLoading} />
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
             
        <Button variant='danger' onClick={apply} disabled={isLoading}>
          <div className='d-flex align-items-center'>{okTitle}</div>
        </Button>
        <Button variant='info' onClick={close} disabled={isLoading}>
          Cancel
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}
export default ReconfirmModal
